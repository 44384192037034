import {
  Select,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select/Select";
import { IRoomType } from "@/models/room/RoomType";
import { SelectGroup } from "@radix-ui/react-select";

type Props = {
  onSelect: (id: number) => void;
  selected: number | null;
  hideAll?: boolean;
  data: IRoomType[];
}

const LabSelect = (props: Props) => { 
  return (
    <>
      <Select value={props.selected !== null ? props.selected + "" : undefined} onValueChange={(v) => props.onSelect(+v)}>
        <SelectTrigger>
          <SelectValue placeholder="Select Room Type" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Select Room Type</SelectLabel>
            { (props.hideAll === false && props.hideAll !== undefined) && (
              <SelectItem value={"-1"}>All</SelectItem>
              // <></>
            )}
            {props.data.map((rt) => (
                <SelectItem key={rt.id} value={rt.id + ""}>{rt.name}</SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  );
};

export default LabSelect;
