
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CancelDialog } from "../../staff_history/components/dialog/CancelDialog";
import { BorrowingAssetDetail, BorrowingDetailActions } from "@/components/borrowing/detail/BorrowingAssetDetail";
import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";

const StudentAssetHistoryDetail = () => {
  const params = useParams();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  
  const fetchData = async () => {
    const id = params.id;
    if (id) {
      try {
        const res = await axios.get(
          `${import.meta.env.VITE_API_URL}asset-borrowing/data`,
          {
            params: { id },
          }
        );
        res.data.data.title = "Asset Borrowing Detail";
        setData(res.data.data);
         
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [params.id]);

  const handleCancel = async (reason: string) => {
    setLoading(true)
    try {
        await axios.patch(
        `${import.meta.env.VITE_API_URL}asset-borrowing/student/cancel`,
        null,
        {
            params: {
            id: params?.id,
            reason: reason,
            },
        }
        );
      setLoading(false)
      fetchData();
    } catch (error) {
      console.error("Error cancelling borrowing:", error);
    }
  };


  const mappedAssets = data?.check_in?.details?.reduce((accumulator : any, detail : any) => {
    const asset = {
      id: detail.asset.id,
      name: detail.asset.name,
      year: detail.asset.year,
      description: detail.asset.description,
      images: detail.asset.images[0]?.path,
    };
  
    if (!accumulator.some((item : any) => item.id === asset.id)) {
      accumulator.push(asset);
    }
  
    return accumulator;
  }, []) || [];

  // console.log(data)
  // console.log(mappedAssets)
  

  return (
    <>
    <LoadingIndicator loading={loading}/>
    <BorrowingAssetDetail
        data = {{
            room: {
                has_assets: true, 
                id: data?.check_in.details[0].asset.room_id,
                room_availability: data?.check_in.details[0].asset.room_availability, 
                room_code: data?.check_in.details[0].asset.room_code, 
                room_name: "", 
              },
              check_in_date: data?.check_in_date || "",
              check_out_date: data?.check_out_date || "",
              borrow_shift: data?.check_out_shift?.shift_name || 0,
              return_shift: data?.check_in_shift?.shift_name || 0,
              purpose: data?.comments, 
              assets: mappedAssets, 
              borrowing_status: data?.status || "Pending",
              finalize_note: data?.status_reason || "",
              reason: data?.comments || "",
              student_nim_leader: data?.student_id || "",
              borrower_email: data?.student_email || "",
              borrower_phone_number: data?.student_phone_number || "",
              created_at: data?.created_at || "",
              id: data?.id.toString(),
        }}
    >
        <BorrowingDetailActions>
        {data?.status === "Pending" && (
            <BorrowingDetailActions>
            <CancelDialog onCancel={handleCancel} />
            </BorrowingDetailActions>
        )}
        </BorrowingDetailActions>
    </BorrowingAssetDetail>
    </>
  );
};

export default StudentAssetHistoryDetail;
