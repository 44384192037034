
import { BorrowingDetail, BorrowingDetailActions } from "@/components/borrowing/detail/BorrowingDetail";
import IBorrowingData from "@/models/borrowing/BorrowingData";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AcceptDialog } from "../components/dialog/AcceptDialog";
import { RejectDialog } from "../components/dialog/RejectDialog";
import { useUserStore } from "@/store/user/userStore";
import useMessierId from "@/lib/hooks/useMessierId";
import { FinalizeDialog } from "../components/dialog/FinalizeDialog";
import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";

const StaffHistoryDetail = () => {
  const params = useParams();
  const [data, setData] = useState<IBorrowingData>();
  const [roomTransactions, setRoomTransactions] = useState<any>([])
  const userStore = useUserStore()
  const { messierId } = useMessierId();
  const [loading, setLoading] = useState<any>(false);

  const fetchData = async (id : string | undefined) => {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}borrowing/data`,
      {
        params: {
          id: id,
        },
      }
    );
    res.data.data.title = "Borrowing Detail";
    setData(res.data.data);
  };

  useEffect(() => {
    const id = params.id;
    
    if (id) {
      fetchData(id);
    }
  }, [params.id]);

  useEffect(() => {
    let ignore = false
      const fetchRoomTransactions = async () => {
        const params = {
          date: data?.date,
          room_id: data?.room?.id,
          room_code: data?.room?.room_code
        };
    
        try {
            const res = await axios.get(
              `${import.meta.env.VITE_API_URL}shift/available`,
              {
                params: params
                ,
                withCredentials: true,
              }
            );

            if (res.data.status) {
              if(!ignore)
              setRoomTransactions(res.data.data)
          } else {
              console.error('Error:', res.data.message);
          }
        } catch (error) {
            console.error('Axios error:', error);
        }
    };

    if(data != null)
    fetchRoomTransactions()

    return () => {
      ignore = true;
    };
  }, [data])

  const handleAccept = async (reason: string) => {
    setLoading(true);
    try {
      // console.log({
      //   id: params?.id,
      //   cid: messierId,
      //   username: userStore.data?.UserName,
      //   reason: reason,
      // })
      await axios.patch(import.meta.env.VITE_API_URL + "borrowing/staff/accept", null, {
        params: {
          id: params?.id,
          cid: messierId,
          username: userStore.data?.UserName,
          reason: reason,
        },
      });
      setLoading(false);
      fetchData(params?.id);
    } catch (error: unknown) {
      console.error("Unknown error:", error);
    }
  };

  const handleReject = async (reason: string, reason_type: string) => {
    setLoading(true);
    try {
      await axios.patch(import.meta.env.VITE_API_URL + "borrowing/staff/reject", null, {
        params: {
          id: params?.id,
          username: userStore.data?.UserName,
          reason: reason,
          reason_type: reason_type
        },
      });
      setLoading(false);
      fetchData(params?.id);
    } catch (error: unknown) {
      console.error("Unknown error:", error);
    }
  };

  const handleFinalize = async (status: string) => {
    // console.log({
    //   id: params?.id,
    //   status: status,
    //   username: userStore.data?.UserName,
    //   email : data?.userData?.email,
    //   description: status, 
    //   asset_calibrations:  JSON.stringify([]), 
    //   student_nim_leader : data?.student_nim_leader
    // })
    setLoading(true);
    try {
      await axios.patch(import.meta.env.VITE_API_URL + "borrowing/staff/finalize", null, {
        params: {
          id: params?.id,
          status: status,
          username: userStore.data?.UserName,
          email : data?.userData?.email,
          description: status,
          student_nim_leader : data?.student_nim_leader
          // asset_calibrations:  JSON.stringify([]), 
        },
      });
      setLoading(false);
      fetchData(params?.id);
    } catch (error) {
      console.error("Unknown error:", error);
    }
  };

  return (
    <>
    <LoadingIndicator loading={loading}/>
    <BorrowingDetail data={data}>
      {data?.borrowing_status === "Pending" && (
        <BorrowingDetailActions>
          <AcceptDialog title="Room" transactions={roomTransactions} onAccept={handleAccept} />
          <RejectDialog title="Room" transactions={roomTransactions} onReject={handleReject} />
        </BorrowingDetailActions>
      )}
       {data?.borrowing_status === "Accepted" && (
        <BorrowingDetailActions>
          <FinalizeDialog onFinalize={handleFinalize} dialogTitle="Finalize"/>
        </BorrowingDetailActions>
      )}
    </BorrowingDetail>
    </>
  );
};

export default StaffHistoryDetail;
