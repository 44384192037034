import {
  BorrowingDetail,
  BorrowingDetailActions,
} from "@/components/borrowing/detail/BorrowingDetail";
import { Button } from "@/components/ui/button/Button";
import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";
import { useToast } from "@/lib/hooks/useToast";
import IAsset from "@/models/asset/Asset";
import { useRoomBorrowingStore } from "@/store/room_borrowing/roomBorrowingStore";
import { useUserStore } from "@/store/user/userStore";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SubmitBorrowing = () => {
  const roomBorrowingStore = useRoomBorrowingStore();
  const userStore = useUserStore();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const convertTeam = () => {
    const team = roomBorrowingStore.data.team;
    const detail: {
      id: number;
      borrowing_id: number;
      student_nim: string;
    }[] = [];
    team.forEach((t) => {
      detail.push({
        id: 0,
        borrowing_id: 0,
        student_nim: t.binusian_id,
      });
    });
    return detail;
  };
  const { toast } = useToast();

  const formatDate = (date: Date | undefined): string => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}/${day}/${year}`;
  };

  const submitBorrowing = async() => {
    const borrowingData = roomBorrowingStore.data;
    const shiftIds: Array<number> = [];
    borrowingData.shift.forEach((shift) => {
      shiftIds.push(+shift.id);
    });
    setLoading(true)

    let date = borrowingData.date;
      if (typeof date === 'string') {
        date = new Date(date);
      }
  
      // console.log({
      //   date: formatDate(date),
      //   room_id: borrowingData.room,
      //   shifts: shiftIds,
      //   purpose: borrowingData.purpose,
      //   need_internet: borrowingData.need_internet,
      //   need_chemicals: borrowingData.need_chemicals,
      //   assets: convertAssetsToIds(borrowingData.assets),
      //   team: borrowingData.team ?? [],
      //   student_nim_leader: userStore.data?.BinusianId,
      //   borrower_email: userStore.data?.Email,
      //   borrower_phone_number: borrowingData.phone,
      //   borrower_count: borrowingData.borrower_count
      // })
      try {
        const res: any = await axios.post(import.meta.env.VITE_API_URL + "borrowing/student/submit", {
          date: formatDate(date),
          room_id: borrowingData.room,
          shifts: shiftIds,
          purpose: borrowingData.purpose,
          need_internet: borrowingData.need_internet,
          need_chemicals: borrowingData.need_chemicals,
          assets: convertAssetsToIds(borrowingData.assets),
          team: borrowingData.team ?? [],
          student_nim_leader: userStore.data?.BinusianId,
          borrower_email: userStore.data?.Email,
          borrower_phone_number: borrowingData.phone,
          borrower_count: borrowingData.borrower_count
        });
      
        setLoading(false);
        if (res.data.data.status != 1) {
          toast({
            title: "Submit error.",
            description: res.data.data.message,
          });
        }else{
          roomBorrowingStore.reset();
          localStorage.removeItem('room_borrowing');
          navigate("/");
        }
      
      } catch (error : any) {
        setLoading(false);
        const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
        toast({
            title: "Submit error.",
            description: error?.response?.data?.errors ?? errorMessage,
        });
      }
      
  };

  const convertAssetsToIds = (assets: IAsset[]): number[] => {
    return assets.map(asset => asset.id);
  }
  
  return (
    <BorrowingDetail
      data={{
        title : "Borrowing Data Confirmation",
        assets: roomBorrowingStore.data.assets,
        borrowing_status: "Request",
        date: roomBorrowingStore.data.date
          ? roomBorrowingStore.data.date.toString()
          : "",
        finalize_note: "",
        reason: "",
        need_internet: roomBorrowingStore.data.need_internet ?? false,
        student_nim_leader: userStore.data?.BinusianId,
        userData: {
          email: userStore.data?.Email,
          phone: roomBorrowingStore.data.phone,
          purpose: roomBorrowingStore.data.purpose,
          borrower_count: roomBorrowingStore.data.borrower_count,
        },
        created_at: "",
        id: 0,
        room: {
          has_assets: roomBorrowingStore.data.assets.length > 0 ? true : false,
          id: roomBorrowingStore.data.room,
          room_availability: true,
          room_code: roomBorrowingStore.data.room_code,
          room_name: "",
        },
        detail_borrowings: [...convertTeam()],
        detail_shifts: [...roomBorrowingStore.data.shift],
        semester: {
          id: 0,
          semester_description: "Current",
        },
      }}
    >
      <LoadingIndicator loading={loading}/>
      <BorrowingDetailActions>
        <Button disabled={loading} variant={"blue"} onClick={() => submitBorrowing()}>
          Submit
        </Button>
      </BorrowingDetailActions>
    </BorrowingDetail>
  );
};

export default SubmitBorrowing;
