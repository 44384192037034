import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AcceptDialog } from "../components/dialog/AcceptDialog";
import { RejectDialog } from "../components/dialog/RejectDialog";
import { useUserStore } from "@/store/user/userStore";
import useMessierId from "@/lib/hooks/useMessierId";
import { FinalizeDialog } from "../components/dialog/FinalizeDialog";
import { BorrowingAssetDetail } from "@/components/borrowing/detail/BorrowingAssetDetail";
import { BorrowingDetailActions } from "@/components/borrowing/detail/BorrowingDetail";
import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";

const StaffAssetHistoryDetail = () => {
  const params = useParams();
  const [data, setData] = useState<any>(null);
  const [borrowingCurrentResponse, setBorrowingCurrentResponse] = useState<any>(null);
  const [assetBorrowingCurrentResponse, setAssetBorrowingCurrentResponse] = useState<any>(null);
  const userStore = useUserStore();
  const { messierId } = useMessierId();
  const [loading, setLoading] = useState<any>(false);

  const fetchData = async (id: string | undefined) => {
    try {
      setLoading(true)
      const res = await axios.get(
        `${import.meta.env.VITE_API_URL}asset-borrowing/data`,
        {
          params: {
            id: id,
          },
        }
      );
      setLoading(false)
      res.data.data.title = "Asset Borrowing Detail";
      setData(res.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (data) {
      postAssetBorrowingCurrentAsset();
      postBorrowingCurrentAsset();
    }
  }, [data]);

  useEffect(() => {
    const id = params.id;

    if (id) {
      fetchData(id);
    }
  }, [params.id]);

  const handleAccept = async (reason: string) => {
    setLoading(true)
    try {
      await axios.patch(`${import.meta.env.VITE_API_URL}asset-borrowing/staff/accept`, null, {
        params: {
          id: params?.id,
          cid: messierId,
          username: userStore.data?.UserName,
          reason: reason,
        },
      });
      setLoading(false);
      fetchData(params?.id);
    } catch (error) {
      console.error("Error accepting asset borrowing:", error);
    }
  };

  const postBorrowingCurrentAsset = async () => {
    const payload = {
      data: {
        asset_ids: assetIds,
        check_in_shift_id: data.check_in_shift.id.toString(),
        check_out_shift_id: data.check_out_shift.id.toString(),
        check_in_date: data.check_in_date,
        check_out_date: data.check_out_date
      }
    };

    try {
      const res = await axios.post(`${import.meta.env.VITE_API_URL}borrowing/staff/current-asset`, payload, {
        withCredentials: true,
      });
      setBorrowingCurrentResponse(res.data.data); 
    } catch (error) {
      console.error("Error posting borrowing current asset:", error);
    }
  };

  const postAssetBorrowingCurrentAsset = async () => {
    const payload = {
      data: {
        asset_ids: assetIds,
        check_in_shift_id: data.check_in_shift.id.toString(),
        check_out_shift_id: data.check_out_shift.id.toString(),
        check_in_date: data.check_in_date,
        check_out_date: data.check_out_date
      }
    };
    
    try {
      const res = await axios.post(`${import.meta.env.VITE_API_URL}asset-borrowing/staff/current-asset`, payload, {
        withCredentials: true,
      });
      setAssetBorrowingCurrentResponse(res.data.data);
    } catch (error) {
      console.error("Error posting asset borrowing current asset:", error);
    }
  };

  const handleReject = async (reason: string) => {
    setLoading(true);
    try {
      await axios.patch(`${import.meta.env.VITE_API_URL}asset-borrowing/staff/reject`, null, {
        params: {
          id: params?.id,
          username: userStore.data?.UserName,
          reason: reason,
        },
      });
      setLoading(false)
      fetchData(params?.id);
    } catch (error) {
      console.error("Error rejecting asset borrowing:", error);
    }
  };

  const handleFinalizeCheckin = async (status: string) => {
    setLoading(true)
    const assetIds = mappedAssets.map((asset: any) => asset.id.toString());

    const param = {
      greenlit_id: params.id,
      check_in_id: data?.check_in?.id.toString(),
      username: userStore.data?.UserName || "",
      borrower_email: data?.student_email || "",
      shift_id: data?.check_in_shift?.id.toString() || "",
      status: status,
      description: status,
      check_in_notes: "",
      check_in_detail_ids: data?.check_in?.details?.map((detail: any) => detail.asset.id.toString()) || [],
      assets: assetIds || [],
      asset_calibrations: []
    };

    try {
      await axios.patch(`${import.meta.env.VITE_API_URL}asset-borrowing/staff/finalize-check-in`, { data: param });
      setLoading(false)
      fetchData(params?.id);
    } catch (error) {
      console.error("Error finalizing check-in:", error);
    }
  };

  const handleFinalizeCheckout = async (status: string) => {
    setLoading(true)
    try {
      await axios.patch(`${import.meta.env.VITE_API_URL}asset-borrowing/staff/finalize-check-out`, {
        data: {
          greenlit_id: params.id,
          check_out_id: data?.check_out?.id.toString(),
          username: userStore.data?.UserName || "",
          borrower_email: data?.student_email || "",
          shift_id: data?.check_out_shift?.id.toString() || "",
          status: status,
          description: status,
          check_out_notes: "",
          check_out_detail_ids: data?.check_in?.details?.map((detail: any) => detail.asset.id.toString()) || [],
          assets: data?.check_in?.details?.map((detail: any) => detail.asset.id.toString()) || [],
          asset_calibrations: []
        },
      });
      setLoading(false)
      fetchData(params?.id);
    } catch (error) {
      console.error("Error finalizing check-out:", error);
    }
  };

  const mappedAssets = data?.check_in?.details?.map((detail: any) => ({
    id: detail.asset.id,
    name: detail.asset.name,
    year: detail.asset.year,
    description: detail.asset.description,
    images: detail.asset.images[0]?.path
  })) || [];

  const assetIds = mappedAssets.map((asset : any) => ({ id: asset.id.toString() }));

  const roomId = data?.check_in?.details[0].asset.room.id;
  const roomCode = data?.check_in?.details[0].asset.room.room_code;
  const roomTypeId = data?.check_in?.details[0].asset.room.room_type_id;
  
  return (
    <>
    <LoadingIndicator loading={loading}/>
    <BorrowingAssetDetail data={{
      room: {
        has_assets: true,
        id: data?.check_in.details[0].asset.room_id,
        room_availability: data?.check_in.details[0].asset.room_availability,
        room_code: data?.check_in.details[0].asset.room_code,
        room_name: "",
      },
      check_in_date: data?.check_in_date || "",
      check_out_date: data?.check_out_date || "",
      borrow_shift: data?.check_out_shift?.id || 0,
      return_shift: data?.check_in_shift?.id || 0,
      purpose: data?.comments,
      assets: mappedAssets,
      borrowing_status: data?.status || "Pending",
      finalize_note: data?.status_reason || "",
      reason: data?.comments || "",
      student_nim_leader: data?.student_id || "",
      borrower_email: data?.student_email || "",
      borrower_phone_number: data?.student_phone_number || "",
      created_at: data?.created_at || "",
      id: data?.id.toString(),
    }}>
      {data?.status === "Pending" && (
        <BorrowingDetailActions>
          <AcceptDialog
            onAccept={handleAccept}
            transactions={borrowingCurrentResponse}
            borrowingCurrent={borrowingCurrentResponse}
            assetBorrowingCurrent={assetBorrowingCurrentResponse}
            title="Asset"
          />
          <RejectDialog onReject={handleReject} />
        </BorrowingDetailActions>
      )}
      {data?.status === "Accepted" && (
        <BorrowingDetailActions>
          <FinalizeDialog onFinalize={handleFinalizeCheckin} dialogTitle="Finalize Check In"
           onUpdate={() => fetchData(params?.id)}
           borrowingCurrent={borrowingCurrentResponse}
           assetBorrowingCurrent={assetBorrowingCurrentResponse}
           check_in_date={data.check_in_date}
           check_in_shift={data.check_in_shift}
           room_id={roomId}           
           room_code={roomCode}      
           room_type_id={roomTypeId}
           assetId={data?.id}  />
        </BorrowingDetailActions>
      )}
      {data?.status === "Check-In Finalized" && (
        <BorrowingDetailActions>
          <FinalizeDialog onFinalize={handleFinalizeCheckout} dialogTitle="Finalize Check Out"
           onUpdate={() => fetchData(params?.id)}
           borrowingCurrent={borrowingCurrentResponse}
           assetBorrowingCurrent={assetBorrowingCurrentResponse}
           check_out_date={data.check_out_date}
           check_out_shift={data.check_out_shift}
           room_id={roomId}           
           room_code={roomCode}      
           room_type_id={roomTypeId}
           assetId={data?.id} />
        </BorrowingDetailActions>
      )}
    </BorrowingAssetDetail>
    </>
  );
};

export default StaffAssetHistoryDetail;
