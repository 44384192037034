import { useState, useEffect, useRef, ReactNode } from "react";
import { IRoom } from "@/models/room/Room";
import { Option } from "@/components/multi-select-filter/MultiSelectFilter";
import { Grid, GridItem } from "@/components/ui/grid/Grid";
import { useSearchParams } from "react-router-dom";
import Search from "@/components/search/Search";
import { FilterModal } from "@/components/modal/filter/FilterModal";
import SkeletonGrid from "@/components/ui/skeleton/SkeletonGrid";
import axios from "axios";

type Props = {
  data: IRoom[];
  loading: boolean;
  selected: number;
  onSelect: (room: IRoom) => void;
  selectedSoftware?: Option[];
  onSoftwareChange: (selectedSoftware: Option[]) => void; 
  children?: ReactNode
  onSubmit: () => void
};

const RoomSelect = (props: Props) => {
  const [rooms, setRooms] = useState<IRoom[]>(props.data);
  const data = useRef<IRoom[]>(props.data);
  const [searchParams, setSearchParams] = useSearchParams();
  const [softwares, setSoftwares] = useState<Option[]>([]);

  useEffect(() => {
    let ignore = false;

    const fetchSoftwares = async () => {
      try {
          const response = await axios.get(import.meta.env.VITE_API_URL + 'software/all');

          if (response.data.status) {
              const fetchedSoftwares = response.data.data.map((software : any) => ({
                  id: software.SoftwareId, 
                  name: software.Name 
              }));
              if (!ignore) setSoftwares(fetchedSoftwares);
          } else {
              console.error('Error fetching software data:', response.data.message);
          }
      } catch (error) {
          console.error('Error during API call:', error);
      }
    };

    fetchSoftwares()

    return () => {
      ignore = true;
    };
  },[])

  useEffect(() => {
    setRooms(props.data);
    data.current = props.data;
  }, [props.data]);

  const handleSoftwareChange = (selectedSoftware: Option[]) => {
      props.onSoftwareChange(selectedSoftware);
  };

  const handleSearch = (search: string) => {
    setSearchParams({ ...searchParams, search: search });
  };

  useEffect(() => {
    const searchData = (value: string | null) => {
      /**
       * search kasih sini aja, biar request gk kebanyakan.
       */
      if (value === null) setRooms(data.current);
      else {
        const searchValue = value.toLowerCase();
        setRooms([
          ...data.current.filter(
            (d) =>
              d.room_code.toLowerCase().includes(searchValue) || 
              d.room_name.toLowerCase().includes(searchValue) 
          ),
        ]);
      }
    };
    searchData(searchParams.get("search"));
  }, [searchParams]);
  

  return (
    <div className="w-full min-h-60">
      <div className="flex gap-4">
        {props.selectedSoftware && (<FilterModal
          options={softwares}
          onSelectOption={handleSoftwareChange}
          selected={props.selectedSoftware}
          title="Software Filter"
          triggerText="Software"
          onSubmit={props.onSubmit}
        />)}
        {props.children}
      </div>
      <Search
        containerClassName="py-4"
        onSearch={handleSearch}
        placeHolder="Search Room Code / Name"
      />
      {props.loading ? (
        <SkeletonGrid count={20} />
      ) : (
        <Grid header="Choose one of Laboratory Room you want to borrow.">
          {rooms.map((r) => (
            <GridItem
              key={r.id}
              isSelected={props.selected === r.id}
              onClick={() => props.onSelect(r)}
            >
              <span className="font-semibold text-sm md:text-base">
                {r.room_code}
              </span>
              <span className="text-xs md:text-sm">
                {r.room_name ?? "No Description"}
              </span>
            </GridItem>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default RoomSelect;
