import { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button/Button";
import { Link } from "react-router-dom";
import styles from "./Home.module.css";
import {  dateFormat, getStatusClass } from "@/lib/utils";
import { useUserStore } from "@/store/user/userStore";

// Define types here
interface Semester {
  id: number;
  semester_description: string;
  status: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
}

interface RoomType {
  id: number;
  name: string;
}

interface HybridStatus {
  id: number;
  name: string;
}

interface Room {
  id: number;
  room_code: string;
  room_name: string;
  ip: string;
  capacity: number;
  room_delay: number;
  status: string;
  type: RoomType;
  hybrid_status: HybridStatus;
  has_assets: number;
}

interface UserData {
  phone: string;
  email: string;
  purpose: string;
  borrower_count: number;
}

interface DetailShift {
  id: number;
  name: string;
  is_available: number;
  start_time: string;
  end_time: string;
}

interface BorrowingItem {
  id: number;
  semester: Semester;
  room: Room;
  student_nim_leader: string;
  date: string;
  userData: UserData;
  borrowing_status: string;
  attendance_status: string | null;
  reason: string;
  finalize_note: string | null;
  need_internet: number;
  detail_shifts: DetailShift[];
  created_at: string;
}

interface ApiResponse {
  message: string;
  data: {
    data: BorrowingItem[];
  };
}

const HomePage: React.FC = () => {
  const userStore = useUserStore();
  const [borrowedItems, setBorrowedItems] = useState<BorrowingItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      if (userStore.data) {
        setLoading(true);
        try {
          const response = await axios.get<ApiResponse>(
            import.meta.env.VITE_API_URL+`borrowing/student/recent-history`,
            {
              params: {
                nim: userStore.data.BinusianId,
                count: 3,
              },
            }
          );
          if (ignore) return;

          const { message, data } = response.data;

          if (message === "success") {
            setBorrowedItems(data.data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setBorrowedItems([]);
        }
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      ignore = true;
    };
  }, [userStore.data]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className={styles.container}
      style={{
        background: `center / cover no-repeat url("/images/home_bg.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
      }}
    >
      <div className="flex flex-col w-full px-2 items-center md:px-10 gap-2">
        <h1 className="text-3xl sm:text-5xl lg:text-7xl font-bold text-center md:text-left w-full font-secondary">
          Lab Facility
        </h1>
        <h4 className="text-lg sm:text-2xl lg:text-3xl font-semibold text-primary text-center md:text-left w-full pb-2">
          Borrow room and facility @BINUS University
        </h4>
        <Link
          to={userStore.data !== undefined ? "/borrowing/student" : "/login"}
          className="w-full text-center md:text-left"
        >
          <Button
            className="w-48 bg-amber-600 transition-all ease-linear text-white hover:bg-amber-600/90 hover:scale-105"
            type="button"
          >
            Start Now
          </Button>
        </Link>
      </div>
      <motion.div
        animate={{ opacity: [1, 0.7, 0.65, 0.7, 1] }}
        transition={{ duration: 10, repeat: Infinity }}
        className="flex justify-start items-center flex-col w-full md:w-[48rem]"
      >
        <div className="text-secondary-foreground text-2xl border-b-primary border-b w-full text-center md:block hidden font-semibold">
          Recently Borrowed
        </div>
        {loading ? (
          <div className="w-full text-center text-xl p-4">
            Loading...
          </div>
        ) : borrowedItems?.length === 0 || userStore.data === undefined ? (
          <div className="w-full text-center text-xl p-4">
            {userStore.data === undefined ? (
              <>You are not logged in yet. <Link to={"/login"} className="link">Login here</Link></>
            ) : "You have not borrowed anything"}
          </div>
        ) : (
          <>
            <div className="flex flex-row w-full justify-between pt-2">
              <div className="text-center text-lg w-full">Room</div>
              <div className="text-center text-lg w-full">Status</div>
              <div className="text-center text-lg w-full">Date</div>
            </div>
            <div className="flex flex-col w-full overflow-hidden gap-2 p-2">
              {borrowedItems?.map(({ id, room, borrowing_status, date }) => (
                <Link
                  key={id}
                  to={`/history/student/${id}`}
                  className="flex flex-row py-2 items-center justify-between w-full h-10 rounded-sm bg-[#41a2d3]/25 text-secondary-foreground text-sm"
                >
                  <div className="text-center w-full">{room?.room_code}</div>
                  <div className={`w-full text-center ${getStatusClass(borrowing_status)}`}>
                    {borrowing_status}
                  </div>
                  <div className="w-full text-center text-blue-400">
                    {dateFormat(new Date(date))}
                  </div>
                </Link>
              ))}
            </div>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};

export default HomePage;
