import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";
import { dateFormat } from "@/lib/utils";
import IBorrowingData from "@/models/borrowing/BorrowingData";
import axios from "axios";
import { ReactNode, useEffect, useState } from "react";

type ActionProps = {
  children: ReactNode;
};

const BorrowingDetailActions = (props: ActionProps) => {
  return (
    <div className="flex flex-row justify-end py-4">
      <div className="flex flex-row-reverse gap-4">{props.children}</div>
    </div>
  );
};

type Props = {
  data: IBorrowingData | undefined;
  children: ReactNode;
};

export const extractShiftNames = (shifts: any): string => {
  const shiftNumbers = shifts.map((shift: any) => {
    const match = shift.name.match(/^(Shift (\d+))/);
    return match ? match[2] : '';
  }).filter((num : any) => num !== '');

  if (shiftNumbers.length === 0) {
    return '';
  }

  // Add "Shift" in front of the first shift number and join the rest with commas
  return `Shift ${shiftNumbers[0]}${shiftNumbers.length > 1 ? ', ' + shiftNumbers.slice(1).join(', ') : ''}`;
};

const BorrowingDetail = (props: Props) => {
  const data = props.data;
  const [teamMembersNames, setTeamMembersNames] = useState<Record<string, string>>({});

  useEffect(() => {
    if (data) {
      const fetchNames = async () => {
        const names = await Promise.all(
          data.detail_borrowings.map(async (d) => {
            try {
              const response = await axios.get(import.meta.env.VITE_API_URL + `user/get-name?credential=${d.student_nim}`);
              return { nim: d.student_nim, name: response.data.data };
            } catch (error) {
              console.error(`Failed to fetch name for ${d.student_nim}`, error);
              return { nim: d.student_nim, name: d.student_nim }; 
            }
          })
        );

        const namesMapping = names.reduce((acc, { nim, name }) => {
          acc[nim] = name;
          return acc;
        }, {} as Record<string, string>);

        setTeamMembersNames(namesMapping);
      };

      fetchNames();
    }
  }, [data]);
  const getStatusColor = (status: string) => {
    switch (status) {
      case "Accepted":
        return "text-green-600";
      case "Finalized":
        return "text-green-600";
      case "Rejected":
        return "text-red-600";
      case "Pending":
        return "text-muted-foreground";
      case "Canceled":
        return "text-red-600";
      default:
        break;
    }
  };


  return (
    <>
      {data ? (
        <div className="flex-col px-6 pb-4 pt-12 w-full">
          <div className="text-xl font-bold">{data.title}</div>
          <div className="text-sm font-semibold text-green-600 pb-4">
            {data.id > 0 ? <>ID {data.id}</> : <>New</>}
          </div>
          <section className="flex flex-col pb-4 lg:flex-row w-full gap-4 items-start lg:h-[17rem] h-auto">
            <div className="w-full flex flex-col flex-1 gap-1 p-4 rounded-lg bg-secondary min-w-fit min-h-fit h-auto lg:h-full lg:w-auto">
              <div className="text-lg font-semibold">
                Room : {data.room.room_code}
              </div>
              <div className="text-muted-foreground text-sm">
                {data.room.room_name ?? ""}
              </div>
              <div className="text-muted-foreground text-sm">
                {data.room.type?.name}
              </div>
              <div className="grid columns-2"></div>
              <div className="text-sm grid grid-cols-1 lg:grid-cols-2 w-fit gap-x-2 gap-y-1">
                <div>Borrowing Date</div>
                <div className="text-blue-500 font-semibold">
                  {dateFormat(data.date)} ({extractShiftNames(data?.detail_shifts)})
                </div>
                <div>Request Date</div>
                <div className="text-orange-500 font-semibold">
                  {data.created_at === ""
                    ? dateFormat(new Date())
                    : dateFormat(data.created_at)}
                </div>
                <div>Status</div>
                <div
                  className={`${getStatusColor(
                    data.borrowing_status
                  )} font-semibold`}
                >
                  {data.borrowing_status}
                </div>
                {data?.reason && (<>
                <div>Reason</div>
                <div className="text-muted-foreground lg:max-w-[14rem] lg:max-h-[4rem] overflow-hidden text-ellipsis">
                  {data.reason}
                </div>
                </>)}
              </div>
            </div>
            <div className="flex flex-col gap-1 p-4 rounded-lg bg-secondary w-full lg:w-auto lg:h-full overflow-auto">
              <div className="text-lg font-semibold">Team Members</div>
              {data.detail_borrowings.length === 0 ? (
                <div>No Team Member</div>
              ) : (
                data.detail_borrowings?.map((d, i) => (
                  <div
                    className="flex gap-1 items-center font-semibold"
                    key={i}
                  >
                    <div>{i + 1}. </div>
                    <div
                      className={`text-sm ${
                        d.student_nim === data.student_nim_leader &&
                        "text-green-500"
                      }`}
                      key={d.id}
                    >
                      {d.student_nim}{" - "} {teamMembersNames[d.student_nim]} {" "}
                        {d.student_nim === data.student_nim_leader && <>(Leader)</>}
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="flex flex-col max-h-[17rem] lg:max-h-full lg:h-full overflow-auto w-full flex-1 gap-1 p-4 rounded-lg bg-secondary">
              <div className="text-lg font-semibold">
                Assets : {data.assets?.length}
              </div>
              {data.assets?.map((a) => (
                <div key={a.id} className="flex flex-row gap-4 py-4">
                  <img
                    src={
                      a.images && a.images.length > 0
                        ? a.images[0].path
                        : "https://picsum.photos/200/300"
                    }
                    className="h-24 w-24"
                    alt="image not found"
                  />
                  <div className="flex flex-col">
                    <div className="text-sm font-semibold">{a.name}  -  {a.id}</div>
                    <div className="text-sm">{a.description}</div>
                    <div className="text-sm">{a.year}</div>
                  </div>
                </div>
              ))}
              
              {!data.assets || (data.assets.length < 1 && <div className="w-[14rem]">No Assets</div>)}
            </div>
          </section>
          <section className="flex flex-col flex-1 gap-1 p-4 rounded-lg bg-secondary w-full lg:w-auto min-h-60">
            <div className="text-lg font-semibold">
              Borrower : {data.student_nim_leader}
            </div>
            <div className="text-sm grid grid-cols-1 lg:grid-cols-2 w-fit gap-y-1">
              <div>Email</div>
              <div className="text-muted-foreground">{data.userData.email}</div>
              <div>Phone</div>
              <div className="text-muted-foreground">{data.userData.phone}</div>
              <div>Purpose</div>
              <div className="text-muted-foreground">{data.userData.purpose}</div>
              <div>Internet</div>
              <div className="text-muted-foreground">
                {!data.need_internet ? "No" : "Yes"}
              </div>
            </div>
          </section>
          {props.children}
        </div>
      ) : (
        <LoadingIndicator loading={true}/>
      )}
    </>
  );
};

export { BorrowingDetail, BorrowingDetailActions };
